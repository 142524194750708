import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";
import BenefitTable from "../component/benefit-table";

export default function BenefitsManagement() {
  return (
    <>
      <div className="grid lg:grid-cols-12">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />
          <div className="bg-[#f0f4f8]">
            <div className="w-[95%] mx-auto py-10">
              <div className="md:flex justify-between">
                <p className="font-inter font-[700] text-[25px] text-black">
                  {/* Benefits Management */}
                  Gestion des avantages sociaux
                </p>

                <div className="md:flex gap-3 mt-5 md:mt-0">
                  <div className="relative hidden md:block">
                    <input
                      type="text"
                      placeholder="Rechercher"
                      className="font-inter font-[500] text-[14px] text-[#BFBFBF] bg-white rounded-full py-2 px-8 outline-none "
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 absolute top-2 left-2 text-[#BFBFBF]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </div>
                  <div className="relative block md:hidden">
                    <input
                      type="text"
                      placeholder="Rechercher"
                      className="font-inter font-[500] text-[14px] text-[#BFBFBF] bg-white rounded-full py-2 px-8 outline-none w-full"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 absolute top-2 left-2 text-[#BFBFBF]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </div>
                  <button className="mt-5 md:mt-0 flex justify-center gap-2 font-inter font-[500] text-[16px] text-white bg-[#8D6AFF] py-2 w-full px-5 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mt-[2px]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    {/* Add User */}
                    Ajouter un utilisateur
                  </button>
                </div>
              </div>
              <BenefitTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
